import { Grid, Paper, Typography, List, ListSubheader, ListItem, Link, Button } from '@material-ui/core';
import moment from 'moment';
import logo from '../../images/logo-black.png';
import { makeStyles } from '@material-ui/core/styles';
import { humanize } from '../common/lib';

const useStyles = makeStyles((theme) => ({
    root:{
        padding: '16px'
    },
    image: {
        cursor: 'pointer',
        marginRight: '16px',
        height: '100px',
        objectFit: 'cover'
    },
    paper: {
        padding: '16px',
        width: "100%"
    },
    logo:{
        width: "70%",
    },
    list: {
        position: "relative",
        overflow: "auto",
        width: '100%',
        paddingBottom: 0,
        boxShadow: theme.shadows[5] + '!important',
        marginTop: '16px'
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    listSubheader:{
        backgroundColor: theme.palette.grey[300],
        color: "black",
        paddingTop: "8px",
        paddingBottom: "8px",
        borderBottom: "1px solid " + theme.palette.grey[300]
    },
    listItem: {
        borderBottom: "2px solid " + theme.palette.grey[300] + '!important',
        backgroundColor: 'white'
    }
}));

const QuoteSummary = ({data}) => {
    const classes = useStyles();
    const {customer, items, installPricingMode, opening_service, customer_notes} = data;
    const {
        trim = {}, 
        doors = {}, 
        shelf = {}, 
        extra = {}
    } = items;

    const trimTotal = Object.values(trim).reduce((acc, type) => {
        return acc + type.reduce((acc, item) => acc + (Number(item.price) * Number(item.count)), 0);
    }, 0);
    const doorsTotal = Object.values(doors).reduce((acc, type) => {
        return acc + type.reduce((acc, item) => acc + (Number(item.price) * Number(item.count)), 0);
    }, 0);
    const shelfTotal = Object.values(shelf).reduce((acc, type) => {
        return acc + type.reduce((acc, item) => acc + (Number(item.price) * Number(item.count)), 0);
    }, 0);
    const extraTotal = Object.values(extra).reduce((acc, type) => {
        return acc + type.reduce((acc, item) => acc + (Number(item.price) * Number(item.count)), 0);
    }, 0);
    const openingServiceTotal = (Number(opening_service.price) || 0) * (Number(opening_service.count) || 0);
    const totalInstall = extraTotal + openingServiceTotal;
    const subTotal = trimTotal + doorsTotal + shelfTotal + totalInstall;
    const subTotalAdj = Math.ceil(subTotal);
    const total = subTotal * 1.13;

    return (
        <div className={classes.root}>
            <Grid
                container
                spacing={2}
            >
                <Grid item xs={12}>
                    <Paper className={classes.paper} elevation={4}>
                        <Grid container spacing={2}>
                            <Grid item xs={4} md={4}>
                                <img alt='logo' src={logo} className={classes.logo}/>
                            </Grid>
                            <Grid item xs={4} md={4}>
                                <Typography variant="h6">Quote</Typography>
                                <Typography variant="body2">Valid until {moment(data.linkExpiresAt).format('MMM Do YYYY')}</Typography>
                                <Typography variant="body2">By {data.createdBy.name}</Typography>
                                {
                                    data.createdBy.email &&
                                    <Typography variant="body2">Email: {data.createdBy.email}</Typography>
                                }
                                {
                                    data.createdBy.phone &&
                                    <Typography variant="body2">Phone: {data.createdBy.phone}</Typography>
                                }
                            </Grid>
                            <Grid item xs={4} md={4}>
                                <Typography variant="h6">{data.code}</Typography>
                                {
                                    data.createdAt &&
                                    <Typography variant="body2">Created {moment(data.createdAt).format('MMM Do YYYY')}</Typography>
                                }
                                {
                                    <Typography variant="body2">${Number(subTotalAdj).toFixed(2)} + HST</Typography>
                                }
                                {
                                    <Typography variant="body1">${Number(total).toFixed(2)}</Typography>
                                }
                            </Grid>
                            <Grid item xs={4} md={4}>
                                <Typography variant="h6">Customer Information</Typography>
                                <Typography variant="body1">
                                    {data.customer?.name || data.prospect?.name || 'No customer information'}
                                </Typography>
                                {
                                    customer.phone &&
                                    <Typography variant="body2">
                                        Tel: {customer.phone}
                                    </Typography>
                                }
                                <Typography variant="body2">
                                    {customer.address || ''}
                                </Typography>
                                <Typography variant="body2">
                                    {customer.address2 || ''}
                                </Typography>
                                <Typography variant="body2">
                                    {customer.city ? `${customer.city},` : ''} {customer.postalCode || ''}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="h6">Site Information</Typography>
                                {
                                    Object.keys(data.site || {})
                                    .filter(field => data.site[field])
                                    .map((field, i) => (
                                        <Typography key={i} variant="body2">
                                            {humanize(field.replace('model', ''))}: {data.site[field]}
                                        </Typography>
                                    ))
                                }
                            </Grid>
                            <Grid item xs={4} md={4}>
                                <Button variant="contained" color="primary" component={"a"} href={"https://www.riversidemillwork.ca/product/make-a-payment/"} target="_blank">Pay Now</Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
            {
                customer_notes?.trim() &&
                <Grid item xs={12}>
                    <Paper className={classes.paper} elevation={4}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h6">Customer Notes</Typography>
                                <Typography variant="body2">{customer_notes?.replace("\\n",`\\n\\r`)}</Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            }
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <List className={classes.list + " groupedList"} subheader={<li />}>
                        {
                            Object.keys(trim).length > 0 && (
                                <ListSubheader className={classes.listSubheader}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={5}><Typography variant="body1">Trim</Typography></Grid>
                                        <Grid item xs={2}><Typography variant="body1">Length</Typography></Grid>
                                        <Grid item xs={2}><Typography variant="body1">Price</Typography></Grid>
                                        <Grid item xs={3}><Typography variant="body1">Total</Typography></Grid>
                                    </Grid>
                                </ListSubheader>
                            )
                        }
                        {Object.keys(trim).map((type) => (
                            trim[type].map((item) => (
                                <ListItem key={item.code} className={classes.listItem}>
                                    <Grid container>
                                        <Grid item xs={5}>
                                        <Typography variant="body1">{humanize(type)} | {item.code || 'No code'}</Typography>
                                        <Typography variant="caption">{item.description || 'No description'}</Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography variant="body1">{item.count} {item.uom || 'LF'}</Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography variant="body1">
                                            {item.count ? Number(item.price).toFixed(2) : '0.00'}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography variant="body1">
                                            ${((Number(item.price) || 0) * (Number(item.count) || 0)).toFixed(2)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </ListItem>
                        ))))}
                        {
                            Object.keys(trim).length > 0 &&
                            <div>
                                <ListItem className={classes.subTotal}>
                                    <Grid  container spacing={1}>
                                        <Grid item xs={9}><Typography variant="subtitle2">Trim Subtotal</Typography></Grid>
                                        <Grid item xs={3}><Typography variant="subtitle2">${Number(trimTotal.toFixed(2)).toLocaleString(undefined, {minimumFractionDigits: 2})}</Typography></Grid>
                                    </Grid>
                                </ListItem>
                            </div>
                        }
                        {/* Doors */}
                        {
                            Object.keys(doors).length > 0 && (
                                <ListSubheader className={classes.listSubheader}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={5}><Typography variant="body1">Doors</Typography></Grid>
                                        <Grid item xs={2}><Typography variant="body1">No.</Typography></Grid>
                                        <Grid item xs={2}><Typography variant="body1">Price</Typography></Grid>
                                        <Grid item xs={3}><Typography variant="body1">Total</Typography></Grid>
                                    </Grid>
                                </ListSubheader>
                            )
                        }
                        {Object.keys(doors).map((type) => (
                            doors[type].map((item) => (
                                <ListItem key={item.code} className={classes.listItem}>
                                    <Grid container>
                                        <Grid item xs={5}>
                                            <Typography variant="body1">{humanize(type)} | {item.code || 'No code'}</Typography>
                                            <Typography variant="caption">{item.description || 'No description'}</Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography variant="body1">{item.count} {item.uom || 'EA'}</Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography variant="body1">
                                                {item.count ? Number(item.price).toFixed(2) : '0.00'}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Typography variant="body1">
                                                ${((Number(item.price) || 0) * (Number(item.count) || 0)).toFixed(2)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </ListItem>
                        ))))}
                        {
                            Object.keys(doors).length > 0 &&
                            <div>
                                <ListItem className={classes.subTotal}>
                                    <Grid  container spacing={1}>
                                        <Grid item xs={9}><Typography variant="subtitle2">Doors Subtotal</Typography></Grid>
                                        <Grid item xs={3}><Typography variant="subtitle2">${Number(doorsTotal.toFixed(2)).toLocaleString(undefined, {minimumFractionDigits: 2})}</Typography></Grid>
                                    </Grid>
                                </ListItem>
                            </div>
                        }
                        {/* Shelving */}
                        {
                            Object.keys(shelf).length > 0 && (
                                <ListSubheader className={classes.listSubheader}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={5}><Typography variant="body1">Shelf</Typography></Grid>
                                        <Grid item xs={2}><Typography variant="body1">QTY</Typography></Grid>
                                        <Grid item xs={2}><Typography variant="body1">Price</Typography></Grid>
                                        <Grid item xs={3}><Typography variant="body1">Total</Typography></Grid>
                                    </Grid>
                                </ListSubheader>
                            )
                        }
                        {Object.keys(shelf).map((type) => (
                            shelf[type].map((item) => (
                                <ListItem key={item.code} className={classes.listItem}>
                                    <Grid container>
                                        <Grid item xs={5}>
                                        <Typography variant="body1">{humanize(type)} | {item.code || 'No code'}</Typography>
                                        <Typography variant="caption">{item.description || 'No description'}</Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography variant="body1">{item.count} {item.uom || 'EA'}</Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography variant="body1">
                                            {item.count ? Number(item.price).toFixed(2) : '0.00'}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography variant="body1">
                                            ${((Number(item.price) || 0) * (Number(item.count) || 0)).toFixed(2)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </ListItem>
                        ))))}
                        {
                            Object.keys(shelf).length > 0 &&
                            <div>
                                <ListItem className={classes.subTotal}>
                                    <Grid  container spacing={1}>
                                        <Grid item xs={9}><Typography variant="subtitle2">Shelf Subtotal</Typography></Grid>
                                        <Grid item xs={3}><Typography variant="subtitle2">${Number(shelfTotal.toFixed(2)).toLocaleString(undefined, {minimumFractionDigits: 2})}</Typography></Grid>
                                    </Grid>
                                </ListItem>
                            </div>
                        }
                        {/* Extra */}
                        {
                            Object.keys(extra).length > 0 && (
                                <ListSubheader className={classes.listSubheader}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={5}><Typography variant="body1">Install</Typography></Grid>
                                        <Grid item xs={2}><Typography variant="body1">Qty</Typography></Grid>
                                        <Grid item xs={2}><Typography variant="body1">Price</Typography></Grid>
                                        <Grid item xs={3}><Typography variant="body1">Total</Typography></Grid>
                                    </Grid>
                                </ListSubheader>
                            )
                        }
                        {
                            data.installPricingMode === 'contract_pricing' &&
                            (
                                <ListItem key={opening_service.code} className={classes.listItem}>
                                    <Grid container>
                                        <Grid item xs={5}>
                                            <Typography variant="body1">{opening_service.code || 'No code'}</Typography>
                                            <Typography variant="caption">{opening_service.description || 'No description'}</Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography variant="body1">{opening_service.count} {opening_service.uom}</Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography variant="body1">${Number(opening_service.price).toFixed(2)}</Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Typography variant="body1">${((Number(opening_service.price) || 0) * (Number(opening_service.count) || 0)).toFixed(2)}</Typography>
                                        </Grid>
                                    </Grid>
                                </ListItem>
                            )
                        }
                        {Object.keys(extra).map((type) => (
                            extra[type].map((item) => (
                                <ListItem key={item.code} className={classes.listItem}>
                                    <Grid container>
                                        <Grid item xs={5}>
                                        <Typography variant="body1">{humanize(type)} | {item.code || 'No code'}</Typography>
                                        <Typography variant="caption">{item.description || 'No description'}</Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography variant="body1">{item.count} {item.uom}</Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography variant="body1">
                                            {item.count ? Number(item.price).toFixed(2) : '0.00'}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography variant="body1">
                                            ${((Number(item.price) || 0) * (Number(item.count) || 0)).toFixed(2)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </ListItem>
                        ))))}
                        {
                            (Object.keys(extra).length > 0 || 
                            data.installPricingMode === 'contract_pricing') &&
                            <div>
                                <ListItem className={classes.subTotal}>
                                    <Grid  container spacing={1}>
                                        <Grid item xs={9}><Typography variant="subtitle2">Install Subtotal</Typography></Grid>
                                        <Grid item xs={3}><Typography variant="subtitle2">${Number(totalInstall.toFixed(2)).toLocaleString(undefined, {minimumFractionDigits: 2})}</Typography></Grid>
                                    </Grid>
                                </ListItem>
                            </div>
                        }
                    </List>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} style={{padding: '16px'}}>
                    <Typography variant="caption">
                    By signing / approving this quote, you acknowledge this policy and agree to pay the total price inclusive of taxes. In case of non-payment, this document will be used as proof of agreement on quantities
and unit prices. Riverside Millwork Group can only give expected lead times for special orders and cannot be held responsible or liable for lead times of its manufacturers or distributors. Any extra
quantities required subsequent to this sales order (materials or labour) will be subject to extra charges at the same unit rates agreed to above.
Material Return Policy: Returns of regularly stocked items (designated by a |L or |W in the item description) for refund or credit on account are accepted within 30 days of the original purchase date
subject to the following terms and conditions: All returns must be authorized in writing in advance by a Riverside associate. Materials will not be authorized for return if they have been installed, used, cut,
stained, painted, damaged, weathered, or have been modified in any way from their original purchased condition. Hardware must be returned in its original packaging to be eligible for credit / refund. Any
claim for damaged goods must be reported within 24 hours of delivery to be considered for exchange/credit. Special order and/or custom-made materials and hardware are not eligible for exchange,
refund or credit. Materials returned will be subject to a re-stocking fee. If return material requires pick-up from a jobsite, pick-up fees plus a handling charge will apply.
Material Return Procedure: Upon Customer request for material return, a Riverside Associate will inspect the material and complete the authorization. Both the Customer and Riverside Associate must
sign the form acknowledging the quantity of material eligible for return as well as any pick-up, handling, and/or restocking fees to be applied to the return transaction. If the material is to be picked up from
a job site, the Customer must ensure that the site and material will be accessible and ready for the scheduled pick-up. The return transaction will be completed once all approved material has been
received back into the Riverside warehouse. At that time, a refund or credit on account will be processed.
Disclaimer: Please note that the quoted price provided may be subject to changes based on the implementation of tariffs, taxes, or other import/export regulations. Any potential tariffs or duties imposed by
government authorities may affect the final cost of the product or service. We cannot be held liable for any such additional costs, and customers are responsible for any tariffs, taxes, or duties incurred as a
result of these changes. Prices are valid at the time of quotation and are subject to adjustment based on changes in applicable tariffs or regulations.
                    </Typography>
                </Grid>
            </Grid>
        </div>
    );
}

export default QuoteSummary;